import React from "react"
import { Link } from "gatsby"

const NavGallery = () => (
  <nav className="b-nav-gallery">
    <h2 className="b-nav-gallery__heading b-nav-gallery__heading--sr-only">Navigation</h2>
    <Link to="/" className="b-nav-gallery__close" title="Home">
      <span className="b-nav-gallery__close-icon"></span>
      <strong className="b-nav-gallery__close-text">Home</strong>
    </Link>
  </nav>
)

export default NavGallery