import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Location } from '@reach/router'

import Poster from "./poster"

const Header = ({ siteTitle }) => {
  return (
    <Location>
      {({ location }) => {
        return <header className={`b-header${location.pathname === '/' ? ' b-header--fullscreen' : ''}`}>

          <div className="b-header__panel b-header__panel--text">
            <h1 className="b-header__heading">

              {location.pathname === '/' && <>
                  <span className="b-header__heading-title">{siteTitle}</span>
                  <em className="b-header__heading-subline">Painting</em>
                </>
              }
              {location.pathname !== '/' && <Link to="/" className="b-header__heading-link">
                <span className="b-header__heading-title">{siteTitle}</span>
                <em className="b-header__heading-subline">Painting</em>
              </Link>
              }

            </h1>
          </div>

          {location.pathname === '/' && <div className="b-header__panel b-header__panel--poster">
            <Poster />
          </div>
          }
        </header>
      }}
    </Location>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header