import React from "react"
import { Location } from '@reach/router'

const Page = ({ children }) => {
  return (
    <Location>
      {({ location }) => {
        return <div className={`b-page${(location.pathname === '/painting/' || location.pathname === '/illustration/') ? ' b-page--light-on-dark b-page--headerless' : ''}`}>
          {children}
        </div>
      }}
    </Location>
  )
}

export default Page