/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Location } from '@reach/router'
import Headroom from "react-headroom"

import Page from "./page"
import Header from "./header"
import NavGallery from "./nav-gallery"
import Main from "./main"
import Footer from "./footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Location>
        {({ location }) => {
          if (location.pathname === '/painting/' || location.pathname === '/illustration/' ) {

            return <Page>
              <Headroom disableInlineStyles={ true }>
                <NavGallery />
              </Headroom>
              <Main>
                {children}
              </Main>
              <Footer />
            </Page>
          } else {

            return <Page>
              <Header siteTitle={data.site.siteMetadata.title} />    
              <Main>
                {children}
              </Main>
              <Footer />
            </Page>
          }
        }}
      </Location>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

if (typeof window !== 'undefined') {
  const updateVhProp = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  
  updateVhProp();
  
  window.addEventListener('orientationchange', () => {
    const afterOrientationChange = () => {
      updateVhProp();
      window.removeEventListener('resize', afterOrientationChange);
    };
    
    window.addEventListener('resize', afterOrientationChange);
    updateVhProp();
  });
}

export default Layout
