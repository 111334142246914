import React from "react"
import { Link } from "gatsby"
import { Location } from '@reach/router'

const Footer = () => {
  return (
    <Location>
      {({ location }) => {
        return <footer className={`b-footer${(location.pathname === '/painting/' || location.pathname === '/illustration/') ? ' b-footer--light-on-dark' : ''}`}>
          <p className="b-footer__meta">
            © {new Date().getFullYear()} Berlin, <Link to="/imprint/" className="b-footer__meta-link">Imprint</Link>
          </p>
        </footer>
      }}
    </Location>
  )
}

export default Footer