import React from "react"

const Figure = ({ children, content, options }) => (
  <figure className={`b-figure${options.lightOnDark ? ' b-figure--light-on-dark' : ''}${options.sizeXs ? ' b-figure--size-xs' : ''}${options.sizeS ? ' b-figure--size-s' : ''}`}>
    {children}
    <p className="b-figure__figcaption">
      <span className="b-figure__figcaption-text-track">
        {content.figcaption}
      </span>
    </p>
  </figure>
)

export default Figure